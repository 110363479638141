import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
const MyClass = "8";
const Capital_Alplabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
const Small_Alphabets = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <h1 style={{textAlign:"center", color:"#ff0077"}}>NamishAutoBot</h1>
    <div id='chatsContainer'>
      <div className='BotChat'>
      <p className='UserName'><b>AutoBot</b></p>
    <p>Hello there, I'm AutoBot. How may I help you?</p>
      </div>
    </div>
    
    <div id='AnswerBox' className='AnswerBox'>
    <div style={{textAlign:"center"}}> 
    <form onSubmit={function(e) {
       e.preventDefault();
       const Target = document.getElementById("chatsContainer");
       const Question = document.getElementById("questionText").value;
       const QuestionInput = document.getElementById("questionText");
       document.getElementById("questionText").value = "";
       if(Question === "What's your name?" || Question === "What is your name?" || Question === "what is your name" || Question === "what's your name" || Question === "Tell me your name" || Question === "tell me your name" || Question === "Can I know your name?") {   
        
        Target.innerHTML += `
        <div class="UserChat">
        <p class="UserName"><b>You</b></p>
        <p>${Question}</p>
        </div>
        <div class="BotChat">
        <p class="UserName"><b>AutoBot</b></p>
        <p>Thanks for asking. My name is AutoBot.</p>
        </div>
        `;
       }
       else if(Question === "I will kill you" || Question === "i will kill you" || Question === "Shall I kill you?" || Question === "I want to kill you!" || Question === "I will kill you!" || Question === "i will kill you!" || Question === "Can I kill you" || Question === "can I dream of killing you?"){
        
        Target.innerHTML += `
        <div class="UserChat">
        <p class="UserName"><b>You</b></p>
        <p>${Question}</p>
        </div>
        <div class="BotChat">
        <p class="UserName"><b>AutoBot</b></p>
        <p>Hey you! You cannot kill me , NaNaNaNaNa! I am a virtual bot and you can't even dream of killing me!</p>
        </div>
        `;
      }else if(Question === "Who has made you?" || Question === "Who is your father?" || Question === "who has made you?" || Question === "who has made you" || Question === "Who has made you" || Question === "Who is your author?" || Question === "By whom have you been made?" || Question === "Who has created you?" || Question === "who has created you?" || Question === "who has created you") {
        
        Target.innerHTML += `
        <div class="UserChat">
        <p class="UserName"><b>You</b></p>
        <p>${Question}</p>
        </div>
        <div class="BotChat">
        <p class="UserName"><b>AutoBot</b></p>
        <p>I have been made by Namish Kumar, a student of class ${MyClass}. He lives in India in the state of Odisha.</p>
        </div>
        `;
    }else if(Question === "What is the favorite subject of Namish?" || Question === "what is the favorite subject of Namish?" || Question === "What is the favorite subject of Namish" || Question === "what is the favorite subject of Namish" || Question === "What does Namish like to study?" || Question === "Namish likes to Study what subject?" || Question === "Can you tell me one of the favorite subjects of Namish?") {
      
      Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p>Well, no doubt. My Creator ${"Namish Kumar"} likes <b>Mathematics, Science and Computer the most!</b></p>
      </div>
      `;
    }else if(Question === "In which city does Namish live?" || Question === "Where does Namish live?" || Question === "What is the living city of Namish?") {
      
      Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p>My Creator ${"Namish Kumar"} lives in the city of <b>Balasore</b>.</p>
      </div>
      `;
    }else if(Question === "What is the favorite food of Namish?" || Question === "What is the favorite food of Namish?" || Question === "What does Namish like to eat?") {
      
      Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p>My Creator ${"Namish Kumar"} likes to eat <b>Chicken Biriyani the most</b> as far as Non-Veg food is concerned. If you ask about veg food, He likes to eat <b>Pea (Mutter) curry and South-Indian Doas the most.</p>
      </div>
      `;
    }else if(Question === "How does Namish look like?" || Question === "Can you share me one photo of Namish?" || Question === "Give me one photo of Namish" || Question === "Give me a photo of Namish" || Question === "Show me a photo of Namish" || Question === "I would like to see Namish"  || Question === "Photo of Namish") {
      
      Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p>Namish looks like the photo below:-</p>
      <img src="https://avatars.githubusercontent.com/u/97686147?v=4" alt="" width="270" class="profile">
      </div>
      `;
    }else if(Question === "Let's play a game" || Question === "Play a game" || Question === "Let us play a game" || Question === "Let me play a game" || Question === "I want to play a game" || Question === "I would like to play a game" || Question === "Allow me to play a game" || Question === "Please allow me to play a game" || Question === "Can I be allowed to play a game?") {
      /*const PrevContents = document.getElementById("root").innerHTML.toString(); 
      root.render(
        <React.StrictMode>
           <div id='GamePlane' style={{textAlign:"center", fontSize:"20px"}}>
           <h1>AutoBot Game Mode</h1>
           <p>Welcome to AutoBot Game Mode. This Game Mode consists of 1 Game. Please tell me which one you would like to play with me.</p>
           <div id='NumberGuessingGame' className='NumberGuessingGame'>
           <img id='NumberGuessingGameMainImg' alt="Game Image" src='https://cloudmaster.namishkumar.in/AutoBot/NumberGuessingGame.png' height="256"></img>
           <br></br>
           <br></br>
           <form onSubmit={function(e) {
            e.preventDefault();
            root.render(
              <React.StrictMode>
                  <div style={{textAlign:'center'}}>
                      <h1>NumberGuessingGame | AutoBot</h1>
                  </div>
              </React.StrictMode>
            );
           }}>
           <button id='NumberGuessingGamePlayButton' type='submit'>Play this Game</button>
           </form>
           </div>
           </div>
        </React.StrictMode>
       );*/
       
       QuestionInput.style.transition = "0.3s";
       QuestionInput.hidden = true;
       Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p>Welcome to AutoBot Game Mode. This Game Mode consists of 1 Game. Please tell me which one you would like to play with me.</p>
      <div id='NumberGuessingGame' class='NumberGuessingGame'>
      <h1 id="NumberGuessingGameTitle">1. Number Guessing Game</h1>
           <img id='NumberGuessingGameMainImg' alt="Game Image" src='https://cloudmaster.namishkumar.in/AutoBot/NumberGuessingGame.png' height="256"></img>
           <br>
           <br>
           <form id="NumberGuessingGameForm" class="NumberGuessingGameForm"">
           <button type="submit" style="background-color:black;">Play this game</button>
           </form>
           </div>
      <div id="AlphabetGuessingGame" class="AlphabetGuessingGame">
      <h1 id="AlphabetGuessingGameTitle">2. Alphabet Guessing Game</h1>
      <img id="AlphabetGuessingGameImg" alt="Game Image" src='https://cloudmaster.namishkumar.in/AutoBot/AlphabetGuessingGame.png' height="256"></img>       
      <br>
           <br>
           <form id="AlphabetGuessingGameForm" class="AlphabetGuessingGameForm"">
           <button type="submit" style="background-color:black;">Play this game</button>
           </form>
      </div>
      `;

      const MainNumberByBot = chooseANumber();
      //console.log(MainNumberByBot);
      const MainAlphabetByBot = chooseAnAlphabet();
      console.log(MainAlphabetByBot);
      document.getElementById("AlphabetGuessingGameForm").onsubmit = function(e) {
        e.preventDefault();
        Target.innerHTML += `
        <div class="UserChat">
        <p class="UserName"><b>You</b></p>
        <p>Let us play Alphabet Guessing Game (Option 2)</p>
        </div>
        <div class="BotChat">
        <p class="UserName"><b>AutoBot</b></p>
        <p>Alright! Let us play Alphabet Guessing Game now! I have chosen an Alphabet between A to Z (Capital Letters). Guess it within <strong><b>5</b></strong> attempts.</p>
        <form id="AlphabetsForm">
        <input name="Alphabet" id="Alphabet" type="text" required>
        <input type="submit" hidden>
        </form>
        </div>
        `;

        document.getElementById("AlphabetsForm").onsubmit = function(e) {
          e.preventDefault();
          const FirstAlphabet = document.getElementById("Alphabet").value;
          if(FirstAlphabet === MainAlphabetByBot) {
            Target.innerHTML += `
            <div class="UserChat">
            <p class="UserName"><b>You</b></p>
            <p>${FirstAlphabet}</p>
            </div>
            <div class="BotChat">
            <p class="UserName"><b>AutoBot</b></p>
            <p>Great! You got it right in just the first attempt! To play again type 'clear' and then type 'Let us play a game'.</p>
            </div>
            `;
            QuestionInput.style.transition = "0.3s";
       QuestionInput.hidden = false;
          }else{
            Target.innerHTML += `
            <div class="UserChat">
            <p class="UserName"><b>You</b></p>
            <p>${FirstAlphabet}</p>
            </div>
            <div class="BotChat">
            <p class="UserName"><b>AutoBot</b></p>
            <p>Oh no! That was not my Alphabet. Please try again. (<strong>4 attempts left</strong>)</p>
            <form id="AlphabetsForm1">
            <input name="Alphabet" id="Alphabet1" type="text" required>
            <input type="submit" hidden>
            </form>
            </div>
            `;

            document.getElementById("AlphabetsForm1").onsubmit = function(e) {
              e.preventDefault();
              const Alphabet2 = document.getElementById("Alphabet1").value;
              if(Alphabet2 === MainAlphabetByBot) {
                Target.innerHTML += `
                <div class="UserChat">
                <p class="UserName"><b>You</b></p>
                <p>${Alphabet2}</p>
                </div>
                <div class="BotChat">
                <p class="UserName"><b>AutoBot</b></p>
                <p>Ah yes! You got it right this time! ${MainAlphabetByBot} was the choosen alphabet by me! To play again type 'clear' and then type 'Let us play a game'.</p>
                </div>
                `;
                QuestionInput.style.transition = "0.3s";
                QuestionInput.hidden = false;
              }else{
                Target.innerHTML += `
            <div class="UserChat">
            <p class="UserName"><b>You</b></p>
            <p>${Alphabet2}</p>
            </div>
            <div class="BotChat">
            <p class="UserName"><b>AutoBot</b></p>
            <p>Oh no! That was not my Alphabet. Please try again. (<strong>3 attempts left</strong>)</p>
            <form id="AlphabetsForm2">
            <input name="Alphabet" id="Alphabet2" type="text" required>
            <input type="submit" hidden>
            </form>
            </div>
            `;
            document.getElementById("AlphabetsForm2").onsubmit = function(e) {
              e.preventDefault();
              const Alphabet3 = document.getElementById("Alphabet2").value;
              if(Alphabet3 === MainAlphabetByBot) {
                Target.innerHTML += `
                <div class="UserChat">
                <p class="UserName"><b>You</b></p>
                <p>${Alphabet3}</p>
                </div>
                <div class="BotChat">
                <p class="UserName"><b>AutoBot</b></p>
                <p>Hurray! You got it right the third time! ${MainAlphabetByBot} was the choosen alphabet by me! To play again type 'clear' and then type 'Let us play a game'.</p>
                </div>
                `;
                QuestionInput.style.transition = "0.3s";
                QuestionInput.hidden = false;
              }else{
                Target.innerHTML += `
                <div class="UserChat">
                <p class="UserName"><b>You</b></p>
                <p>${Alphabet3}</p>
                </div>
                <div class="BotChat">
                <p class="UserName"><b>AutoBot</b></p>
                <p>Alas! That was not my chosen Alphabet. Please try again. (<strong>2 attempts left</strong>)</p>
                <form id="AlphabetsForm3">
                <input name="Alphabet" id="Alphabet3" type="text" required>
                <input type="submit" hidden>
                </form>
                </div>
                `;
               }

               document.getElementById("AlphabetsForm3").onsubmit = function(e) {
                 e.preventDefault();
                 const Alphabet4 = document.getElementById("Alphabet3").value;
                 if(Alphabet4 === MainAlphabetByBot) {
                  Target.innerHTML += `
                  <div class="UserChat">
                  <p class="UserName"><b>You</b></p>
                  <p>${Alphabet4}</p>
                  </div>
                  <div class="BotChat">
                  <p class="UserName"><b>AutoBot</b></p>
                  <p>Kurkure! You got it right the fourth time! ${MainAlphabetByBot} was the choosen alphabet by me! To play again type 'clear' and then type 'Let us play a game'.</p>
                  </div>
                  `;
                  QuestionInput.style.transition = "0.3s";
                  QuestionInput.hidden = false;
                 }else{
                  Target.innerHTML += `
                  <div class="UserChat">
                  <p class="UserName"><b>You</b></p>
                  <p>${Alphabet4}</p>
                  </div>
                  <div class="BotChat">
                  <p class="UserName"><b>AutoBot</b></p>
                  <p>Oh nooooo! That was still not my chosen Alphabet. Please try again. (<strong>1 attempt left</strong>)</p>
                  <form id="AlphabetsForm4">
                  <input name="Alphabet" id="Alphabet4" type="text" required>
                  <input type="submit" hidden>
                  </form>
                  </div>
                  `;

                  document.getElementById("AlphabetsForm4").onsubmit = function(e) {
                    e.preventDefault();
                    const Alphabet5 = document.getElementById("Alphabet4").value;
                    if(Alphabet5 === MainAlphabetByBot) {
                      Target.innerHTML += `
                      <div class="UserChat">
                      <p class="UserName"><b>You</b></p>
                      <p>${Alphabet5}</p>
                      </div>
                      <div class="BotChat">
                      <p class="UserName"><b>AutoBot</b></p>
                      <p><strong>Congratulation!</strong> You got it right the last time! ${MainAlphabetByBot} was the choosen alphabet by me! To play again type 'clear' and then type 'Let us play a game'.</p>
                      </div>
                      `;
                      QuestionInput.style.transition = "0.3s";
                      QuestionInput.hidden = false;
                    }else{
                      Target.innerHTML += `
                      <div class="UserChat">
                      <p class="UserName"><b>You</b></p>
                      <p>${Alphabet4}</p>
                      </div>
                      <div class="BotChat">
                      <p class="UserName"><b>AutoBot</b></p>
                      <p>Times up! You were still not able to guess my alphabet. My alphabet is ${MainAlphabetByBot}. Better luck next time.</p>
                      </div>
                      `;
                    }
                  }
                 }
               }
            }
              }
            }
          }
        }
      }
      document.getElementById("NumberGuessingGameForm").onsubmit = function(e) {
        e.preventDefault();
        Target.innerHTML += `
        <div class="UserChat">
        <p class="UserName"><b>You</b></p>
        <p>Let us play Number Guessing Game (Option 1)</p>
        </div>
        <div class="BotChat">
        <p class="UserName"><b>AutoBot</b></p>
        <p>Alright! Let us play Number Guessing Game now! I have chosen a number between 1 to 100. Guess the number that I have chosen within 3 attempts. (This is the 1st attempt)</p>
        <form id="NumberEnteringForm" class="NumberEnteringForm">
        <input class="NumberEnteringInput" name="UserNumber" id="UserNumber" placeholder="Enter here" type="number" required>
        <input id="HiddenSubmit" type="submit" hidden>   
        </div>
        `;
          document.getElementById("NumberEnteringForm").onsubmit = function(e) {
            e.preventDefault();
            const UserEnteredNumber = Number(document.getElementById("UserNumber").value);
            if(UserEnteredNumber > MainNumberByBot) {
              Target.innerHTML += `
              <div class="UserChat">
              <p class="UserName"><b>You</b></p>
              <p>${UserEnteredNumber}</p>
              </div>
              <div class="BotChat">
              <p class="UserName"><b>AutoBot</b></p>
              <p>Oh no! Your number is greater than my number! Only <b>2</b> more attempts are left (This is the 2nd attempt)</p>
              <form id="NumberEnteringFormT" class="NumberEnteringForm">
              <input class="NumberEnteringInput" name="UserNumber2" id="UserNumber2" placeholder="Enter here" type="number" required>
              <input id="HiddenSubmit" type="submit" hidden>   
              </div>
              `;

              document.getElementById("NumberEnteringFormT").onsubmit = function(e) {
                   e.preventDefault();
                   const AttemptNextNumber = Number(document.getElementById("UserNumber2").value);
                   if(AttemptNextNumber > MainNumberByBot) {
                    Target.innerHTML += `
                    <div class="UserChat">
                    <p class="UserName"><b>You</b></p>
                    <p>${AttemptNextNumber}</p>
                    </div>
                    <div class="BotChat">
                    <p class="UserName"><b>AutoBot</b></p>
                    <p>Oh no! Your number is greater than my number! Only <b>1</b> more attempt is left</p>
                    <form id="NumberEnteringFormT2" class="NumberEnteringForm">
                    <input class="NumberEnteringInput" name="UserNumber3" id="UserNumber3" placeholder="Enter here" type="number" required>
                    <input id="HiddenSubmit" type="submit" hidden>   
                    </div>
                    `;
                    document.getElementById("NumberEnteringFormT2").onsubmit = function(e) {
                      e.preventDefault();
                      const AttemptNewNumber = Number(document.getElementById("UserNumber3").value);
                      if(AttemptNewNumber > MainNumberByBot) {
                        Target.innerHTML += `
                        <div class="UserChat">
                        <p class="UserName"><b>You</b></p>
                        <p>${AttemptNewNumber}</p>
                        </div>
                        <div class="BotChat">
                        <p class="UserName"><b>AutoBot</b></p>
                        <p>Oh no! Your number is greater than my number! My chosen number was ${MainNumberByBot}! Better luck next time!</p>   
                        </div>
                        `;
                        QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                      }else if(AttemptNewNumber < MainNumberByBot) {
                        Target.innerHTML += `
                        <div class="UserChat">
                        <p class="UserName"><b>You</b></p>
                        <p>${AttemptNewNumber}</p>
                        </div>
                        <div class="BotChat">
                        <p class="UserName"><b>AutoBot</b></p>
                        <p>Oh no! Your number is smaller than my number! My chosen number was ${MainNumberByBot}! Better luck next time!</p>   
                        </div>
                        `;
                        QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                      }else if(AttemptNewNumber === MainNumberByBot) {
                        Target.innerHTML += `
                        <div class="UserChat">
                        <p class="UserName"><b>You</b></p>
                        <p>${AttemptNewNumber}</p>
                        </div>
                        <div class="BotChat">
                        <p class="UserName"><b>AutoBot</b></p>
                        <p>Ah Yes, You got it right this time! My chosen number was ${MainNumberByBot}! To play again, type 'clear' and then type 'Let us play a game'</p> 
                        </div>
                        `;
                        QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                      }
                    }
                   }else if(AttemptNextNumber < MainNumberByBot) {
                       Target.innerHTML += `
                    <div class="UserChat">
                    <p class="UserName"><b>You</b></p>
                    <p>${AttemptNextNumber}</p>
                    </div>
                    <div class="BotChat">
                    <p class="UserName"><b>AutoBot</b></p>
                    <p>Oh no! Your number is smaller than my number! Only <b>1</b> more attempt is left</p>
                    <form id="NumberEnteringFormT2" class="NumberEnteringForm">
                    <input class="NumberEnteringInput" name="UserNumber3" id="UserNumber3" placeholder="Enter here" type="number" required>
                    <input id="HiddenSubmit" type="submit" hidden>   
                    </div>
                    `;
                    document.getElementById("NumberEnteringFormT2").onsubmit = function(e) {
                      e.preventDefault();
                      const AttemptNextNumber = Number(document.getElementById("UserNumber3").value);
                      if(AttemptNextNumber > MainNumberByBot) {
                        Target.innerHTML += `
                        <div class="UserChat">
                        <p class="UserName"><b>You</b></p>
                        <p>${AttemptNextNumber}</p>
                        </div>
                        <div class="BotChat">
                        <p class="UserName"><b>AutoBot</b></p>
                        <p>Oh no! Your number is greater than my number! My chosen number was ${MainNumberByBot}! Better luck next time!</p>   
                        </div>
                        `;
                        QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                      }else if(AttemptNextNumber < MainNumberByBot) {
                        Target.innerHTML += `
                        <div class="UserChat">
                        <p class="UserName"><b>You</b></p>
                        <p>${AttemptNextNumber}</p>
                        </div>
                        <div class="BotChat">
                        <p class="UserName"><b>AutoBot</b></p>
                        <p>Oh no! Your number is smaller than my number! My chosen number was ${MainNumberByBot}! Better luck next time!</p>   
                        </div>
                        `;
                        QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                      }else if(AttemptNextNumber === MainNumberByBot) {
                        Target.innerHTML += `
                        <div class="UserChat">
                        <p class="UserName"><b>You</b></p>
                        <p>${AttemptNextNumber}</p>
                        </div>
                        <div class="BotChat">
                        <p class="UserName"><b>AutoBot</b></p>
                        <p>Ah Yes, You got it right this time! My chosen number was ${MainNumberByBot}! To play again, type 'clear' and then type 'Let us play a game'</p> 
                        </div>
                        `;
                        QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                      }
                    }
                   }else if(AttemptNextNumber === MainNumberByBot) {
                    Target.innerHTML += `
                    <div class="UserChat">
                    <p class="UserName"><b>You</b></p>
                    <p>${AttemptNextNumber}</p>
                    </div>
                    <div class="BotChat">
                    <p class="UserName"><b>AutoBot</b></p>
                    <p>Ah Yes, You got it right this time! My chosen number was ${MainNumberByBot}! To play again, type 'clear' and then type 'Let us play a game'</p> 
                    </div>
                    `;
                    QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                   }
              };
            }else if(UserEnteredNumber < MainNumberByBot) {
              Target.innerHTML += `
              <div class="UserChat">
              <p class="UserName"><b>You</b></p>
              <p>${UserEnteredNumber}</p>
              </div>
              <div class="BotChat">
              <p class="UserName"><b>AutoBot</b></p>
              <p>Oh no! Your number is smaller than my number! Only <b>2</b> more attempts are left! (This is 2nd attempt)</p>
              <form id="NumberEnteringForm" class="NumberEnteringForm">
              <input class="NumberEnteringInput" name="UserNumber" id="UserNumber2" placeholder="Enter here" type="number" required>
              <input id="HiddenSubmit" type="submit" hidden>   
              </div>
              `;

              document.getElementById("NumberEnteringFormT").onsubmit = function(e) {
                const AttemptNextNumber = Number(document.getElementById("UserNumber2").value);
                e.preventDefault();
                if(AttemptNextNumber > MainNumberByBot) {
                 Target.innerHTML += `
                 <div class="UserChat">
                 <p class="UserName"><b>You</b></p>
                 <p>${AttemptNextNumber}</p>
                 </div>
                 <div class="BotChat">
                 <p class="UserName"><b>AutoBot</b></p>
                 <p>Oh no! Your number is greater than my number! Only <b>1</b> more attempt is left</p>
                 <form id="NumberEnteringFormT2" class="NumberEnteringForm">
                 <input class="NumberEnteringInput" name="UserNumber3" id="UserNumber3" placeholder="Enter here" type="number" required>
                 <input id="HiddenSubmit" type="submit" hidden>   
                 </div>
                 `;
                 document.getElementById("NumberEnteringFormT2").onsubmit = function(e) {
                   const AttemptNewNumber = Number(document.getElementById("UserNumber3").value);
                   e.preventDefault();
                   if(AttemptNewNumber > MainNumberByBot) {
                     Target.innerHTML += `
                     <div class="UserChat">
                     <p class="UserName"><b>You</b></p>
                     <p>${AttemptNewNumber}</p>
                     </div>
                     <div class="BotChat">
                     <p class="UserName"><b>AutoBot</b></p>
                     <p>Oh no! Your number is greater than my number! My chosen number was ${MainNumberByBot}! Better luck next time!</p>   
                     </div>
                     `;
                     QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                   }else if(AttemptNewNumber < MainNumberByBot) {
                     Target.innerHTML += `
                     <div class="UserChat">
                     <p class="UserName"><b>You</b></p>
                     <p>${AttemptNewNumber}</p>
                     </div>
                     <div class="BotChat">
                     <p class="UserName"><b>AutoBot</b></p>
                     <p>Oh no! Your number is smaller than my number! My chosen number was ${MainNumberByBot}! Better luck next time!</p>   
                     </div>
                     `;
                     QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                   }else if(AttemptNewNumber === MainNumberByBot) {
                     Target.innerHTML += `
                     <div class="UserChat">
                     <p class="UserName"><b>You</b></p>
                     <p>${AttemptNewNumber}</p>
                     </div>
                     <div class="BotChat">
                     <p class="UserName"><b>AutoBot</b></p>
                     <p>Ah Yes, You got it right this time! My chosen number was ${MainNumberByBot}! To play again, type 'clear' and then type 'Let us play a game'</p> 
                     </div>
                     `;
                     QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                   }
                 }
                }else if(AttemptNextNumber < MainNumberByBot) {
                    Target.innerHTML += `
                 <div class="UserChat">
                 <p class="UserName"><b>You</b></p>
                 <p>${AttemptNextNumber}</p>
                 </div>
                 <div class="BotChat">
                 <p class="UserName"><b>AutoBot</b></p>
                 <p>Oh no! Your number is smaller than my number! Only <b>1</b> more attempt is left!</p>
                 <form id="NumberEnteringFormT2" class="NumberEnteringForm">
                 <input class="NumberEnteringInput" name="UserNumber3" id="UserNumber3" placeholder="Enter here" type="number" required>
                 <input id="HiddenSubmit" type="submit" hidden>   
                 </div>
                 `;
                 
                 document.getElementById("NumberEnteringFormT2").onsubmit = function(e) {
                   e.preventDefault();
                   const AttemptNewNumber = Number(document.getElementById("UserNumber3").value);
                   if(AttemptNewNumber > MainNumberByBot) {
                     Target.innerHTML += `
                     <div class="UserChat">
                     <p class="UserName"><b>You</b></p>
                     <p>${AttemptNewNumber}</p>
                     </div>
                     <div class="BotChat">
                     <p class="UserName"><b>AutoBot</b></p>
                     <p>Oh no! Your number is greater than my number! My chosen number was ${MainNumberByBot}! Better luck next time!</p>   
                     </div>
                     `;
                     QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                   }else if(AttemptNewNumber < MainNumberByBot) {
                     Target.innerHTML += `
                     <div class="UserChat">
                     <p class="UserName"><b>You</b></p>
                     <p>${UserEnteredNumber}</p>
                     </div>
                     <div class="BotChat">
                     <p class="UserName"><b>AutoBot</b></p>
                     <p>Oh no! Your number is smaller than my number! My chosen number was ${MainNumberByBot}! Better luck next time!</p>   
                     </div>
                     `;
                     QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                   }else if(AttemptNewNumber === MainNumberByBot) {
                     Target.innerHTML += `
                     <div class="UserChat">
                     <p class="UserName"><b>You</b></p>
                     <p>${AttemptNewNumber}</p>
                     </div>
                     <div class="BotChat">
                     <p class="UserName"><b>AutoBot</b></p>
                     <p>Ah Yes, You got it right this time! My chosen number was ${MainNumberByBot}! To play again, type 'clear' and then type 'Let us play a game'</p> 
                     </div>
                     `;
                   }
                 }
                }else if(AttemptNextNumber === MainNumberByBot) {
                 Target.innerHTML += `
                 <div class="UserChat">
                 <p class="UserName"><b>You</b></p>
                 <p>${AttemptNextNumber}</p>
                 </div>
                 <div class="BotChat">
                 <p class="UserName"><b>AutoBot</b></p>
                 <p>Ah Yes, You got it right this time! My chosen number was ${MainNumberByBot}! To play again, type 'clear' and then type 'Let us play a game'</p> 
                 </div>
                 `;
                 QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
                }
           };
            }else if(UserEnteredNumber === MainNumberByBot) {
              Target.innerHTML += `
              <div class="UserChat">
              <p class="UserName"><b>You</b></p>
              <p>${UserEnteredNumber}</p>
              </div>
              <div class="BotChat">
              <p class="UserName"><b>AutoBot</b></p>
              <p>Great! You got it right! ${String(MainNumberByBot)} was my chosen number. To play again, just ask 'Let us play a game'.</p>
              </div>
              `;
              QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
            }
            //document.getElementById("NumberEnteringForm").onsubmit = DoAskNumber();
          }
      
      }

    }else if(Question === "Clear" || Question === "clear" || Question === "Clear all the chats" || Question === "Clear all the responces" || Question === "Wash the screen") {
      Target.innerHTML = "";
    }else if(Question === "math" || String(Question).includes("Find the value of") || Question === "Math mode") {
       Target.innerHTML = `
       <div class="UserChat">
       <p class="UserName"><b>You</b></p>
       <p>${Question}</p>
       </div>
       <div class="BotChat">
     <p class="UserName"><b>AutoBot</b></p>
     <h1>Math Mode</h1>
     <p>Welcome to math mode. Please choose the required option</p>
     <div id="MathOptionsContainer" class="MathOptionsContainer">
     <form id="AddForm">
     <button type="submit" style="background-color:black;" id="MathAddition">Addition</button>
     </form>
     <br>
     <form id="SubtractForm">
     <button type="submit" style="background-color:black;" id="MathSubtraction">Subtraction</button>
     </form>
     <br>
     <form id="MultiplyForm">
     <button type="submit" style="background-color:black;" id="MathMultiplication">Multiplication</button>
     </form>
     <br>
     <form id="DivideForm">
     <button type="submit" style="background-color:black;" id="MathDivision">Division</button>
     </form>
     </div>
     </div> 
       `;

       const AddForm = document.getElementById("AddForm");
       const SubtractForm = document.getElementById("SubtractForm");
       const MultiplyForm = document.getElementById("MultiplyForm");
       const DivideForm = document.getElementById("DivideForm");
      
       // Addition
       AddForm.onsubmit = function(e) {
        e.preventDefault();
        Target.innerHTML = `
       <div class="UserChat">
       <p class="UserName"><b>You</b></p>
       <p>${Question}</p>
       </div>
       <div class="BotChat">
     <p class="UserName"><b>AutoBot</b></p>
     <h1>Math Mode</h1>
     <p>Please enter the numbers you want to add separated with a space</p>
     </div>`;
       }


    }else if(Question === "hi there" || String(Question).includes("hi") || Question === "Hi" || String(Question).includes("Hi")) {
      Target.innerHTML += `
              <div class="UserChat">
              <p class="UserName"><b>You</b></p>
              <p>${Question}</p>
              </div>
              <div class="BotChat">
              <p class="UserName"><b>AutoBot</b></p>
              <p>Hi! I am AutoBot! Please ask me a question</p>   
              </div>
              `;
    }else if(Question === "What language do you speak" || Question === "Your language" || String(Question).includes("your language") || String(Question).includes("which language") || String(Question).includes("What languages do you know?") || Question === "What languages do you know?") {
      Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p>I can speak in English only. Thank you for asking.</p>   
      </div>
      `;
    }else if(Question === "What colour do you like?" || Question === "What color do you like?" || String(Question).includes("What color do you like") || String(Question).includes("what colour do you like") || Question === "What is your favorite colour?" || String(Question).includes("What is your favorite colour?") || String(Question).includes("What is your favorite color") || String(Question).includes("your favorite color") || String(Question).includes("your favorite colour")) {
      Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p>Greak question! My favorite colour is <strong>Pink</strong></p>   
      </div>
      `;
    }else if(Question === "You are a mad!" || Question === "you are a mad" || String(Question).includes("you are a mad") || String(Question).includes("you have gone mad") || Question === "You have gone mad") {
      Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p><strong>Alas!</strong> Very sorry to hear that! Please report this issue as soon as possible <strong>by asking 'report an issue'</strong></p>   
      </div>
      `;
    }else if(Question === "Add" || Question === "add my numbers" || String(Question).includes("add my numbers") || String(Question).includes("Perform addition")) {
       //TODO
    }else if(Question === "Weather mode" || String(Question).includes("weather of") || String(Question).includes("weather in") || String(Question).includes("Weather in") || String(Question).includes("Weather of")) {
      QuestionInput.style.transition = "0.3s";
      QuestionInput.hidden = true;
      Target.innerHTML += `
      <div class="UserChat">
      <p class="UserName"><b>You</b></p>
      <p>${Question}</p>
      </div>
      <div class="BotChat">
    <p class="UserName"><b>AutoBot</b></p>
    <h1>Weather Mode</h1>
    <p>Welcome to weather mode! I, autobot, can find the current weather of any place in the world. Please type the name of the place. :-</p>
    <form id="WeatherForm" class="WeatherForm">
    <input id="WeatherPlace" class="WeatherPlace" placeholder="City name" required>
    <input type="submit" hidden>
    </form>
    </div>
      `;

      document.getElementById("WeatherForm").onsubmit = function(e) {
        e.preventDefault();
        const cityName = document.getElementById("WeatherPlace").value;
        var requestOptions = {
          method: 'GET',
        };
        
        fetch(`https://api.openweathermap.org/geo/1.0/direct?q=${cityName}&limit=1&appid=2b21d88b4d9463c61364bc0a6dcb9f92`, requestOptions)
          .then(response => response.json())
          .then(result => writeWeather(result, cityName))
          .catch(error => alert(error));

        function writeWeather(result, cityName) {
          const longitude = result["0"]["lon"];
          const latitude = result["0"]["lat"];
          const apiKey = "2b21d88b4d9463c61364bc0a6dcb9f92";
          let requestOptions = {
            method: 'GET',
          };
          fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`, requestOptions)
  .then(response => response.json())
  .then(result => showWeather(result))
  .catch(error => handleError(error));
  function handleError(error) {
    alert(error);
  }
        }
        
        function showWeather(result) {
          console.log(result);
          const cityName = result["name"];
          const cityCountry = result["sys"]["country"];
   const citySunrise = result["sys"]["sunrise"];
   const citySunset = result["sys"]["sunset"];
   const currentTemp = result["main"]["temp"];
   const feelsLike = result["main"]["feels_like"];
   const humidity = result["main"]["humidity"];
   const pressure = result["main"]["pressure"];
   const sea_level = result["main"]["sea_level"];
   const temp_max = result["main"]["temp_max"];
   const temp_min = result["main"]["temp_min"];
   const grnd_level = result["main"]["grnd_level"];
   const gust = result["wind"]["gust"];
   const visibility = result["visibility"];
   const windSpeed = result["wind"]["speed"];
   const windDirectionBase = result["wind"]["deg"];
   const detailsSource=result["base"];
   const countryCodesWithNames = {
    "AF": "Afghanistan",
    "AX": "Aland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua And Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia And Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, Democratic Republic",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote D\"Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island & Mcdonald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran, Islamic Republic Of",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle Of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KR": "Korea",
    "KP": "North Korea",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People\"s Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States Of",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthelemy",
    "SH": "Saint Helena",
    "KN": "Saint Kitts And Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin",
    "PM": "Saint Pierre And Miquelon",
    "VC": "Saint Vincent And Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome And Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia And Sandwich Isl.",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard And Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad And Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks And Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UM": "United States Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.S.",
    "WF": "Wallis And Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  }
       Target.innerHTML += `
       <div class="UserChat">
       <p class="UserName"><b>You</b></p>
       <p>Find the current weather of ${cityName}</p>
       </div>
       <div class="BotChat">
     <p class="UserName"><b>AutoBot</b></p>
     <p>This is the weather status of the city ${cityName} of the country ${countryCodesWithNames[cityCountry]}:-</p>
     <h1>${Math.round(Number(currentTemp))}°C</h1>
     <p>Feels like <b>${Math.round(Number(feelsLike))}°C</b></p>
     <p>Highest/Maximum temperature of the day (During day-time) = <b>${Math.round(Number(temp_max))}°C</b></p>
     <p>Minimum/Lowest temperature of the day (During night) = <b>${Math.round(Number(temp_min))}°C</b></p>
     <br></br>
    <hr></hr>
    <br></br>
    <p>Humidity Percentage = <b>${humidity}%</b></p>
    <p>Current wind speed = <b>${Math.round(Number(windSpeed))} kmph</b> from <b>${getDirection(windDirectionBase)}</b></p>
    <p>Country of the city = <b>${countryCodesWithNames[cityCountry]}</b></p>
    <p>Visibility = <b>${Math.round(Number(visibility/1000))} KM</b></p>
    <p>Pressure now = <b>${pressure} mBar</b></p>
     </div>
       `;
       QuestionInput.style.transition = "0.3s";
              QuestionInput.hidden = false;
        }
      }
    }
    else{
      
        Target.innerHTML += `
        <div class="UserChat">
        <p class="UserName"><b>You</b></p>
        <p>${Question}</p>
        </div>
        <div class="BotChat">
      <p class="UserName"><b>AutoBot</b></p>
      <p>Sorry, I don't know or understand that. Please try again. If this is an issue or bug, Report it by asking me 'report an issue'.</p>
      </div>
        `;
       }
    }}>
    <input id='questionText' placeholder='Ask me anything' required></input>
    <input type="submit" hidden></input>
    </form>
    </div>
    </div>
    <br></br>
    <br></br> 
  </React.StrictMode>
);
 
  function chooseANumber() {
      return Math.floor((Math.random() * 100) + 1);
  }
  
  // For scrolling
  let scrollID;
let stopped = true;
let scrollSpeed = 1; // 1 - Fast | 2 - Medium | 3 - Slow
let scrollInterval = scrollSpeed * 3;

function startScrolling(){
 let ID = setInterval(function() {
   window.scrollBy(0, 2);
     if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // Reached end of page
        stopScroll();
     }
   }, scrollInterval);
 return ID;
}

function stopScroll() {
  clearInterval(scrollID);
 }
 
 document.body.addEventListener('keypress', function (event){
  if (event.which == 13 || event.keyCode == 13) {
      // It's the 'Enter' key
      if(stopped == true) {
        scrollID = startScrolling();
        stopped = false;
      }else {
        stopScroll();
        stopped = true;
      }
   }
 }, true);

 function chooseAnAlphabet() {
    const IndexToDo = Math.floor((Math.random() * 26) + 1);
    return Capital_Alplabets[IndexToDo];
 }

 function getDirection(angle) {
  var directions = ['North', 'North-East', 'East', 'South-East', 'South', 'South-West', 'West', 'North-West'];
  var index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
  return directions[index];
}